import React from "react";
import logo from "./images/logo.png";
import App from "./App";
import ReactDOM from "react-dom";

function SetFormato(valor = 0){
  return new Intl.NumberFormat("en-US").format(valor)+".00"
}

function round(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.round(m) / 100 * Math.sign(num);
}


export default class Appereance extends React.Component{

  constructor(props) {
    super();
    this.state = {showForm:false}
  }

  componentDidMount() {
    this.setDescription();
    this.setAmount();
    this.setSuccess();

  }

  setDescription(){
    //colocamos la descripcion que obtivimos del get desc
    document.getElementById("description").textContent = this.getParameterByName("desc")
  }

  setAmount(){
    //colocamos la cantidad de dinero que cuesta un boleto de acierto al get amount
    document.querySelector("h1 .cantidad").textContent = SetFormato(this.getParameterByName("amount")) + " MXN"
    document.querySelector("h1 .cantidad").setAttribute("value",this.getParameterByName("amount")+".00")
  }

  setSuccess(){
    document.querySelector(".success").textContent = this.getParameterByName("success")
  }

  handleChange(evt){
    var cantidad = Number(this.getParameterByName("amount"))
    var boletos = evt.target.value;
    var total = cantidad * boletos;

    document.querySelector(".cantidad").textContent = SetFormato(total)+" MXN"
    document.querySelector(".cantidad").setAttribute("value",total)

    this.setRetribution()

    document.querySelector(".checbox-retribucion").checked = false


  }

  getParameterByName(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  handleClick(evt){
    var bandera = true;

    document.querySelectorAll(".datas-container input,.datas-container select").forEach(element=>{
      if (element.classList.contains("is-invalid")){
        bandera = false;
      }
    })

    if (!bandera){
      alert("Algunos elementos no han sido llenados correctamente, por favor verifica que todos los campos son de color verde e intentalo de nuevo.")
    }


    var cantidad = document.querySelector(".cantidad").textContent;
    cantidad = cantidad.replaceAll(" MXN","")
    cantidad = cantidad.replaceAll(",","")
    cantidad = cantidad.replaceAll("$","")
    cantidad = cantidad.replace(".00","")


    let formData = new FormData()

    formData.append("firstname", document.getElementById("nombre").value);
    formData.append("email",document.getElementById("correo").value);
    formData.append("phone",document.getElementById("telefono").value)
    formData.append("61",cantidad);
    formData.append("111",cantidad);

    let parameters = new Appereance();


    //se cambian todas menos content
    if(parameters.getParameterByName("r_utm_campaign") != null){
      formData.append("71",parameters.getParameterByName("r_utm_campaign"));
    }else{
      if(parameters.getParameterByName("utm_campaign") != null){
        formData.append("71",parameters.getParameterByName("utm_campaign"));
      }
    }

    if(parameters.getParameterByName("r_tipo_de_donante") !== null){
      formData.append("41",parameters.getParameterByName("r_tipo_de_donante"));
    }else{
      if(parameters.getParameterByName("tipo_de_donante") !== null){
        formData.append("41",parameters.getParameterByName("tipo_de_donante"));
      }
    }

    if(parameters.getParameterByName("r_utm_source") != null){
      formData.append("72",parameters.getParameterByName("r_utm_source"));
    }else{
      if(parameters.getParameterByName("utm_source") != null){
        formData.append("72",parameters.getParameterByName("utm_source"));
      }
    }

    if(parameters.getParameterByName("utm_content") != null){
      formData.append("74",parameters.getParameterByName("utm_content"));
    }

    if(parameters.getParameterByName("r_utm_term") != null){
      formData.append("101",parameters.getParameterByName("r_utm_term"));
    }else{
      if(parameters.getParameterByName("utm_term") != null){
        formData.append("101",parameters.getParameterByName("utm_term"));
      }
    }

    if(parameters.getParameterByName("r_utm_medium") != null){
      formData.append("73",parameters.getParameterByName("r_utm_medium"));
    }else{
      if(parameters.getParameterByName("utm_medium") != null){
        formData.append("73",parameters.getParameterByName("utm_medium"));
      }
    }



    fetch('https://api.savethechildrenmx.org/save_contact.php',{
      method: 'POST',
      body: formData
    }).then(resp=>{
      if(resp.status === 200){
          ReactDOM.render(<App/>, document.querySelector(".app-container"));
          document.querySelector(".app-container").style.display = "flex"
          document.querySelector(".datas-container").style.display = "none"
          evt.target.style.display = "none"
      }else{
        console.error(resp.statusText)
      }
      return resp.text();
    }).then(txt =>{
      console.log(txt)
    })
  }

  verifyState(){
    var flag = true;

    document.querySelectorAll(".datas-container .form-group input").forEach(element=>{
      if(element.classList.contains("is-invalid") || !element.classList.contains("is-valid")){
        flag = false;
      }
    })

    if (flag){
      document.querySelector(".button-continuar").removeAttribute("disabled")
      document.querySelector(".button-continuar").addEventListener("click",this.handleClick)
    }else{
      document.querySelector(".button-continuar").setAttribute("disabled","disabled")
    }
  }

  handleNombre(evt){
    if(evt.target.value[evt.target.value.length -1] == ' ')
    {
      evt.target.value = evt.target.value.substring(0,evt.target.value.length-1)
    }

    if(evt.target.value[0] == ' ')
    {
      evt.target.value = evt.target.value.substring(1,evt.target.value.length-1)
    }
    evt.target.value=evt.target.value.replaceAll("  "," ")

    var regex = new RegExp("^[A-Za-záéíóúÁÉÍÓÚÑñ]+( [A-Za-záéíóúÁÉÍÓÚÑñ]+){2,}$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display=""
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display="flex"
    }

    this.verifyState();
  }

  handleCorreo(evt){
    if(evt.target.value[evt.target.value.length -1] == ' ')
    {
      evt.target.value = evt.target.value.substring(0,evt.target.value.length-1)
    }

    if(evt.target.value[0] == ' ')
    {
      evt.target.value = evt.target.value.substring(1,evt.target.value.length-1)
    }
    evt.target.value=evt.target.value.replaceAll("  "," ")

    var regex = new RegExp("^^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display=""
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display="flex"
    }

    this.verifyState()
  }

  handleTelefono(evt){
    if(evt.target.value[evt.target.value.length -1] == ' ')
    {
      evt.target.value = evt.target.value.substring(0,evt.target.value.length-1)
    }

    if(evt.target.value[0] == ' ')
    {
      evt.target.value = evt.target.value.substring(1,evt.target.value.length-1)
    }
    evt.target.value=evt.target.value.replaceAll("  "," ")


    var regex = new RegExp("^[0-9]{10}$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display=""
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
      evt.target.parentElement.querySelector(".error-message").style.display="flex"
    }

    var letras = new RegExp("[A-ZÑÁÉÍÓÚa-zñáéíóú[^$.|\\-_?*+(){}]");

    if(letras.exec(evt.target.value) != null){
      evt.target.value = evt.target.value.substr(0,evt.target.value.length-1)
    }

    this.verifyState()
  }

  render() {
    return (
      <div className="checkout-container">
        <div className="information-container">
          <img src={logo} alt="stc-logo"/>
          <div className="label-container">
            <h3>Hacer donativo a:</h3>
            <h3>
              FUNDACION MEXICANA DE APOYO INFANTIL AC
            </h3>
            <h3>Para</h3>
            <h1 id="description">
            </h1>
            <h3>Por la cantidad de</h3>
            <div className="cantidad_container">
              <h1><div className="simbol">$</div><div className="cantidad">200</div></h1>
            </div>
            <div className="success"></div>
            <div className="prefijo"></div>
            <div className="datas-container">
              <div className="form-group">
                <label htmlFor="">Por favor escribe tu nombre y 2 apellidos:</label>
                <input type="text" id="nombre" onBlur={this.handleNombre.bind(this)}/>
                <div className="error-message">
                  <label htmlFor="">Error:</label><br/>
                  <p>
                    -El nombre debe de contener solamente letras. <br/>
                    -Debes escribir al menos tu nombre y tus apellidos completos
                  </p>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="">Número telefónico a 10 dígitos:</label>
                <input type="text" id="telefono" onBlur={this.handleTelefono.bind(this)} inputMode="numeric"/>
                <div className="error-message">
                  <label htmlFor="">Error:</label>
                  <p>
                    -El número telefónico no debe contener letras <br/>
                  </p>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="">Correo electrónico:</label>
                <input type="email" id="correo" onChange={this.handleCorreo.bind(this)}/>
                <div className="error-message">
                  <label htmlFor="">Error, revisa que tu correo:</label>
                  <p>
                     -No tenga espacios <br/>
                     -Contenga unicamente un @
                  </p>
                </div>
              </div>

            </div>


            <button onClick={this.handleClick.bind(this)} className="button-continuar" disabled="disabled">Continuar</button>
          </div>
        </div>

        <div className="app-container">

        </div>
      </div>
    );
  }
}